import { cx } from "class-variance-authority";
import MarkdownToJsx from "markdown-to-jsx";
import fr from "../../translations/fr.json";
import { injectValues, t, type TProps } from "./t";

export interface FormattedMarkdownProps extends TProps {
  className?: string;
  "data-test"?: string;
  overrides?: any;
  options?: {
    overrides?: any;
    forceBlock?: boolean;
    forceInline?: boolean;
  };
}

export function Markdown(props: FormattedMarkdownProps) {
  const { id, values } = props;

  const options = {
    ...props.options,
    overrides: {
      a: (props: React.AnchorHTMLAttributes<HTMLAnchorElement>) => (
        <a
          {...props}
          className="font-semibold underline hover:no-underline"
          target="_blank"
          rel="noopener noreferrer"
        >
          {props.children}
        </a>
      ),
      ul: (props: React.HTMLAttributes<HTMLUListElement>) => (
        <ul {...props} className="list-disc flex flex-col gap-y-2" />
      ),
      ...props.overrides,
      ...props.options?.overrides,
    },
  };

  const translations = fr[props.id as keyof typeof fr];

  if (Array.isArray(translations)) {
    return (
      <div
        data-test={props["data-test"] ?? `${props.id}.text`}
        className={cx("flex flex-col gap-y-4", props.className)}
      >
        {translations.map((translation, index) => (
          <MarkdownToJsx key={index} options={options}>
            {injectValues(translation, props.values)}
          </MarkdownToJsx>
        ))}
      </div>
    );
  }

  return (
    <MarkdownToJsx
      className={props.className}
      data-test={props["data-test"] ?? `${props.id}.text`}
      options={options}
    >
      {t({ id, values })}
    </MarkdownToJsx>
  );
}

export const Md = Markdown;
