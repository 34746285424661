import type { ImmutableRouteKey } from "@remix-run/router/dist/utils";
import type { LazyRouteFunction, RouteObject } from "react-router-dom";

type RequireOne<T, Key = keyof T> = Exclude<
  {
    [K in keyof T]: K extends Key ? Omit<T, K> & Required<Pick<T, K>> : never;
  }[keyof T],
  undefined
>;

// a function to retry loading a chunk to avoid chunk load error for out of date code
export const lazyRetry = function (
  componentImport: LazyRouteFunction<RouteObject>,
  name: string,
) {
  return new Promise<RequireOne<Omit<RouteObject, ImmutableRouteKey>>>(
    (resolve, reject) => {
      // check if the window has already been refreshed
      const hasRefreshed = JSON.parse(
        window.sessionStorage.getItem(`retry-${name}-lazy-refreshed`) ||
          "false",
      );
      // try to import the component
      componentImport()
        .then((component) => {
          window.sessionStorage.setItem(
            `retry-${name}-lazy-refreshed`,
            "false",
          ); // success so reset the refresh
          resolve(component);
        })
        .catch((error) => {
          if (!hasRefreshed) {
            // not been refreshed yet
            window.sessionStorage.setItem(
              `retry-${name}-lazy-refreshed`,
              "true",
            ); // we are now going to refresh
            return window.location.reload(); // refresh the page
          }
          reject(error); // Default error behaviour as already tried refresh
        });
    },
  );
};
