import fr from "../../translations/fr.json";

const regexSeparator = /({\w+})/g;

export function translate(
  messages: Record<string, string | string[]>,
  id: string,
  defaultMessage: string = id,
): string | string[] {
  const translation = messages[id];

  if (translation) {
    return translation;
  }

  const [_, ...parts] = id.split(".");

  if (parts.length === 0) {
    return defaultMessage;
  }

  return translate(messages, parts.join("."), defaultMessage);
}

export interface TProps {
  id: string;
  values?: Record<string, string>;
}

export function injectValues(
  translation: string,
  values?: Record<string, string>,
) {
  return translation
    .split(regexSeparator)
    .filter((x) => x)
    .map<string | JSX.Element>((keyOfValue) => {
      if (keyOfValue.match(regexSeparator)) {
        const trans = keyOfValue.substring(1, keyOfValue.length - 1);
        return (values?.[trans] ?? "") as unknown as JSX.Element;
      }

      return keyOfValue as string;
    })
    .join("");
}

export function t(props: TProps) {
  const translations = translate(fr, props.id);

  if (Array.isArray(translations)) {
    return translations
      .map((translation) => {
        if (!regexSeparator.test(translation)) {
          return translation;
        }

        return injectValues(translation, props.values);
      })
      .join("\n\n");
  }

  if (!regexSeparator.test(translations)) {
    return translations ?? props.id;
  }

  return injectValues(translations, props.values);
}

export function T(props: TProps) {
  return <>{t(props)}</>;
}
