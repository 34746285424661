import React from "react";
import ReactDOM from "react-dom/client";
import {
  RouterProvider,
  createBrowserRouter,
  redirect,
} from "react-router-dom";
import { Amplify } from "aws-amplify";
import "./index.css";
import { Home } from "./routes/home";
import { getCognitoUser } from "./utils/env";
import { lazyRetry } from "./utils/lazyRetry";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

Amplify.configure({
  Auth: {
    Cognito: getCognitoUser(),
  },
});

const router = createBrowserRouter([
  {
    lazy: () => lazyRetry(() => import("./routes"), "home"),
    children: [
      {
        index: true,
        element: <Home />,
      },
      {
        path: "tastings",
        lazy: () => lazyRetry(() => import("./routes/tastings"), "tastings"),
        children: [
          {
            path: ":tastingId",
            lazy: () =>
              lazyRetry(
                () => import("./routes/tastings.$tastingId"),
                "tasting",
              ),
            children: [
              {
                path: "rums/:rumId",
                lazy: () =>
                  lazyRetry(
                    () => import("./routes/tastings.$tastingId.rums.$rumId"),
                    "tasting-rum",
                  ),
              },
              {
                path: "quiz/:rumId",
                lazy: () =>
                  lazyRetry(
                    () => import("./routes/tastings.$tastingId.quiz.$rumId"),
                    "tasting-quiz",
                  ),
              },
            ],
          },
        ],
      },
      {
        path: "advent-of-rum",
        lazy: () =>
          lazyRetry(() => import("./routes/advent-of-rum"), "advent-of-rum"),
        children: [
          {
            path: "quiz",
            lazy: () =>
              lazyRetry(
                () => import("./routes/advent-of-rum.quiz"),
                "advent-of-rum-quiz",
              ),
            children: [
              {
                path: ":day",
                lazy: () =>
                  lazyRetry(
                    () => import("./routes/advent-of-rum.quiz.$day"),
                    "advent-of-rum-quiz-day",
                  ),
              },
            ],
          },
          {
            path: "ranking",
            lazy: () =>
              lazyRetry(
                () => import("./routes/advent-of-rum.ranking"),
                "advent-of-rum-ranking",
              ),
          },
        ],
      },
      {
        path: "contact-us",
        lazy: () =>
          lazyRetry(() => import("./routes/contact-us"), "contact-us"),
      },
      {
        path: "sign-out",
        lazy: () => lazyRetry(() => import("./routes/sign-out"), "sign-out"),
      },
      {
        path: "sign-in",
        lazy: () => lazyRetry(() => import("./routes/sign-in"), "sign-in"),
      },
      {
        path: "sign-up",
        lazy: () => lazyRetry(() => import("./routes/sign-up"), "sign-up"),
      },
      {
        path: "forgot-password",
        lazy: () =>
          lazyRetry(
            () => import("./routes/forgot-password"),
            "forgot-password",
          ),
      },
      // { path: "newsletter", action: newsletterAction },
      // { path: "contact-us", lazy: () => import("./routes/contact-us") },
      {
        path: "settings",
        lazy: () => lazyRetry(() => import("./routes/settings"), "settings"),
        children: [
          {
            path: "profile",
            lazy: () =>
              lazyRetry(
                () => import("./routes/settings.profile"),
                "settings-profile",
              ),
          },
          {
            path: "*",
            loader: () => {
              return redirect("profile");
            },
          },
        ],
      },
      {
        path: "404",
        lazy: () => lazyRetry(() => import("./routes/404"), "404"),
      },
      {
        path: "*",
        loader: () => {
          return redirect("/404");
        },
      },
    ],
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

root.render(
  <React.StrictMode>
    <GoogleReCaptchaProvider
      reCaptchaKey={process.env.REACT_APP_GOOGLE_RECAPTCHA_KEY!}
    >
      <RouterProvider router={router} />
    </GoogleReCaptchaProvider>
  </React.StrictMode>,
);
