export function getEndpoint(baseUrl: string) {
  const location = window.location;

  if (location.hostname === "app.qrandspirits.be") {
    return baseUrl.replace("{ENV}", "");
  }

  return baseUrl.replace("{ENV}", "dev.");
}

export function getCognitoUser() {
  if (window.location.hostname === "app.qrandspirits.be") {
    return {
      userPoolId: "eu-west-1_Lu7JjQX2A",
      userPoolClientId: "5m1i6m8p248qltlav49d1nmjgu",
    };
  }

  return {
    userPoolId: "eu-west-1_gsKu6wncl",
    userPoolClientId: "2rpo3g1kbnql5vkva1sng2vajf",
  };
}
