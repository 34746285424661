import { Markdown } from "../components/i18n/Markdown";
import { T } from "../components/i18n/t";

export function Home() {
  return (
    <div className="h-full max-w-3xl px-4 mx-auto text-justify">
      <header>
        <h1 className="my-8 text-2xl font-bold tracking-tight sm:my-8">
          <T id="home.title" />
        </h1>
      </header>
      <img
        src="qrs-banner720.webp"
        srcSet="qrs-banner720.webp 736w, qrs-banner1280.webp 1280w"
        sizes="(min-width: 880px) 736px, (min-width: 640px) calc(80vw + 48px), calc(100vw - 32px)"
        width="1280"
        height="853"
        alt="QR & Spirits banner"
      />
      <section className="my-8">
        <h2 className="flex items-center text-lg font-bold tracking-tight leading-none text-gray-900 md:text-xl lg:text-xl">
          <T id="home.content.section1.title" />
        </h2>
        <Markdown className="mt-8" id="home.content.section1.content" />
      </section>
      <section className="my-8">
        <h2 className="flex items-center text-lg font-bold tracking-tight leading-none text-gray-900 md:text-xl lg:text-xl">
          <T id="home.content.section2.title" />
        </h2>
        <Markdown className="mt-8" id="home.content.section2.content" />
      </section>
      <section className="my-8">
        <h2 className="flex items-center text-lg font-bold tracking-tight leading-none text-gray-900 md:text-xl lg:text-xl">
          <T id="home.content.section3.title" />
        </h2>
        <Markdown className="mt-8" id="home.content.section3.content" />
      </section>
      <section className="my-8">
        <h2 className="flex items-center text-lg font-bold tracking-tight leading-none text-gray-900 md:text-xl lg:text-xl">
          <T id="home.content.section4.title" />
        </h2>
        <Markdown className="mt-8" id="home.content.section4.content" />
      </section>
    </div>
  );
}
